/* FONT PATH
 * -------------------------- */

$elegant-font-path: "../fonts/elegant" !default;

@font-face {
  font-family: 'ElegantIcons';
  src:url('#{$elegant-font-path}/ElegantIcons.eot');
  src:url('#{$elegant-font-path}/ElegantIcons.eot?#iefix') format('embedded-opentype'),
    url('#{$elegant-font-path}/ElegantIcons.woff') format('woff'),
    url('#{$elegant-font-path}/ElegantIcons.ttf') format('truetype'),
    url('#{$elegant-font-path}/ElegantIcons.svg#ElegantIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}